import "@fontsource/noto-sans" // Defaults to weight 400 with all styles included.
import "@fontsource/noto-sans/400-italic.css"
import "@fontsource/noto-sans/700.css"
import React from "react"
import BeContext from "./src/components/be-context";
import Layout from "./src/components/layout";
import './src/styles/global.css'
import { Location } from "@reach/router"

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {

}

export const wrapRootElement = ({ element }) => (
  <Location>{location => <BeContext {...location}>{element}</BeContext>}</Location>
)

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}