
import * as React from "react";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/theme'
import { navigate } from "gatsby";
import { auth, db } from '../../firebase-info';
import { onAuthStateChanged, updateProfile } from "firebase/auth";
import {
	collection,
    doc,
    getDoc,
	getDocs,
} from "firebase/firestore";
import { GamesContext , AuthContext } from "../contexts/contexts.js";

const BeContext = ({ children, location }) => {
    const [user, setUser] = React.useState(null);
    const [userData, setUserData] = React.useState(null);
    const [userRef, setUserRef] = React.useState(null);
    const [gamesInfo, setGamesInfo] = React.useState(null);
    const [loading, setLoading] = React.useState(true)

    const checkLogin = React.useCallback(async () => {
        onAuthStateChanged(auth, async (loggedUser) => {
          if (!loggedUser) {
            const routeIsExperiment = /(\/experiment\/[a-zA-Z0-9]+)/.test(location.pathname);
            const isCreationSuccess = /(\/experiment\/success\/{0,1})/.test(location.pathname);

            if(routeIsExperiment && !isCreationSuccess) return; //if it is a experiment, we allow the user to continue

            setUserRef(null)
            setUserData(null);
            setLoading(true);
            navigate("/");

            return;
          } else if (!userRef || !userData) {
              setUser(loggedUser);

              const uRef = doc(db, "users", loggedUser.uid);
              const userSnapshot = await getDoc(uRef);
              const userData = userSnapshot.data();

              if (!loggedUser.displayName) {
                updateProfile(loggedUser, { displayName: `${userData.name} ${userData.surname}` } );
              }
              
              setUserRef(uRef)
              setUserData(userData);

              return;
          }
        });
    }, [user, userData, userRef, loading]);

    const checkAdminPath = () => {
      const isAdministationPath = /(\/administration.*)/.test(location?.pathname);
      
      if(userData && isAdministationPath){
        if(userData?.role !== "admin"){
          navigate("/dashboard");
          return true;
        }
      }
      return (!userData) || (!location?.pathname);
    };

    const checkGamePath = () => {
      const isGamePath = /(\/gameCreation.*)/.test(location?.pathname);
      if(isGamePath){
        let path = location?.pathname
        if(path.endsWith("/")){
          path = path.substr(0, path.length-1);
        }
        const lastBarIdx = path.lastIndexOf("/");
        const gameName = path.substr(lastBarIdx+1, path.length).replace("-","");

        if(userData?.gamePermissions && !userData?.gamePermissions[gameName].visible){
          navigate("/dashboard");
          return true;
        }
      }
      return (!userData) || (!location?.pathname);
    };

  const loadGames = React.useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, "games"));
    const games = []
    querySnapshot.forEach((doc) => {
        games.push(doc.data());
    });

    setGamesInfo(games);
  }, [gamesInfo]);

  const gamesContextValue = React.useMemo(() => (gamesInfo), [gamesInfo]);

  const authContextValue = React.useMemo(() => ({ user, userData, userRef, loading }), [user, userData, userRef, loading]);

  React.useEffect(() => {
	    checkLogin();
      loadGames();
	}, []);

  React.useEffect(() => {
    setLoading(checkAdminPath() || checkGamePath());
  }, [location?.pathname, userData])

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={authContextValue}>
        <GamesContext.Provider value={gamesContextValue}>
            {children}
        </GamesContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  )
}

export default BeContext;