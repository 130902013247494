exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-administration-download-experiments-jsx": () => import("./../../../src/pages/administration/downloadExperiments.jsx" /* webpackChunkName: "component---src-pages-administration-download-experiments-jsx" */),
  "component---src-pages-administration-index-jsx": () => import("./../../../src/pages/administration/index.jsx" /* webpackChunkName: "component---src-pages-administration-index-jsx" */),
  "component---src-pages-administration-manage-permissions-jsx": () => import("./../../../src/pages/administration/managePermissions.jsx" /* webpackChunkName: "component---src-pages-administration-manage-permissions-jsx" */),
  "component---src-pages-administration-manage-researcher-permissions-jsx": () => import("./../../../src/pages/administration/manageResearcherPermissions.jsx" /* webpackChunkName: "component---src-pages-administration-manage-researcher-permissions-jsx" */),
  "component---src-pages-administration-validate-jsx": () => import("./../../../src/pages/administration/validate.jsx" /* webpackChunkName: "component---src-pages-administration-validate-jsx" */),
  "component---src-pages-checkout-experiment-jsx": () => import("./../../../src/pages/checkoutExperiment.jsx" /* webpackChunkName: "component---src-pages-checkout-experiment-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-experiment-[id]-jsx": () => import("./../../../src/pages/experiment/[id].jsx" /* webpackChunkName: "component---src-pages-experiment-[id]-jsx" */),
  "component---src-pages-experiment-jsx": () => import("./../../../src/pages/experiment.jsx" /* webpackChunkName: "component---src-pages-experiment-jsx" */),
  "component---src-pages-game-creation-beauty-jsx": () => import("./../../../src/pages/gameCreation/beauty.jsx" /* webpackChunkName: "component---src-pages-game-creation-beauty-jsx" */),
  "component---src-pages-game-creation-coin-jsx": () => import("./../../../src/pages/gameCreation/coin.jsx" /* webpackChunkName: "component---src-pages-game-creation-coin-jsx" */),
  "component---src-pages-game-creation-dice-jsx": () => import("./../../../src/pages/gameCreation/dice.jsx" /* webpackChunkName: "component---src-pages-game-creation-dice-jsx" */),
  "component---src-pages-game-creation-dictator-jsx": () => import("./../../../src/pages/gameCreation/dictator.jsx" /* webpackChunkName: "component---src-pages-game-creation-dictator-jsx" */),
  "component---src-pages-game-creation-dictatorreverse-jsx": () => import("./../../../src/pages/gameCreation/dictatorreverse.jsx" /* webpackChunkName: "component---src-pages-game-creation-dictatorreverse-jsx" */),
  "component---src-pages-game-creation-dishonestyaltruistic-jsx": () => import("./../../../src/pages/gameCreation/dishonestyaltruistic.jsx" /* webpackChunkName: "component---src-pages-game-creation-dishonestyaltruistic-jsx" */),
  "component---src-pages-game-creation-dishonestygroup-jsx": () => import("./../../../src/pages/gameCreation/dishonestygroup.jsx" /* webpackChunkName: "component---src-pages-game-creation-dishonestygroup-jsx" */),
  "component---src-pages-game-creation-dishonestyindividual-jsx": () => import("./../../../src/pages/gameCreation/dishonestyindividual.jsx" /* webpackChunkName: "component---src-pages-game-creation-dishonestyindividual-jsx" */),
  "component---src-pages-game-creation-financialdecision-jsx": () => import("./../../../src/pages/gameCreation/financialdecision.jsx" /* webpackChunkName: "component---src-pages-game-creation-financialdecision-jsx" */),
  "component---src-pages-game-creation-goldenballs-jsx": () => import("./../../../src/pages/gameCreation/goldenballs.jsx" /* webpackChunkName: "component---src-pages-game-creation-goldenballs-jsx" */),
  "component---src-pages-game-creation-questions-jsx": () => import("./../../../src/pages/gameCreation/questions.jsx" /* webpackChunkName: "component---src-pages-game-creation-questions-jsx" */),
  "component---src-pages-game-creation-raven-jsx": () => import("./../../../src/pages/gameCreation/raven.jsx" /* webpackChunkName: "component---src-pages-game-creation-raven-jsx" */),
  "component---src-pages-game-creation-taxevasion-jsx": () => import("./../../../src/pages/gameCreation/taxevasion.jsx" /* webpackChunkName: "component---src-pages-game-creation-taxevasion-jsx" */),
  "component---src-pages-game-creation-ultimatumreceiver-jsx": () => import("./../../../src/pages/gameCreation/ultimatumreceiver.jsx" /* webpackChunkName: "component---src-pages-game-creation-ultimatumreceiver-jsx" */),
  "component---src-pages-game-creation-ultimatumsender-jsx": () => import("./../../../src/pages/gameCreation/ultimatumsender.jsx" /* webpackChunkName: "component---src-pages-game-creation-ultimatumsender-jsx" */),
  "component---src-pages-game-creation-videos-jsx": () => import("./../../../src/pages/gameCreation/videos.jsx" /* webpackChunkName: "component---src-pages-game-creation-videos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loading-jsx": () => import("./../../../src/pages/loading.jsx" /* webpackChunkName: "component---src-pages-loading-jsx" */),
  "component---src-pages-my-experiments-jsx": () => import("./../../../src/pages/myExperiments.jsx" /* webpackChunkName: "component---src-pages-my-experiments-jsx" */),
  "component---src-pages-new-experiment-jsx": () => import("./../../../src/pages/newExperiment.jsx" /* webpackChunkName: "component---src-pages-new-experiment-jsx" */),
  "component---src-pages-participant-jsx": () => import("./../../../src/pages/participant.jsx" /* webpackChunkName: "component---src-pages-participant-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */)
}

