
import { createTheme } from '@mui/material/styles';

const COLORS = {
  white: '#FFF',
  lightgray: '#EEE',
  gray: '#DDD',
  darkgray: '#8B8B8B',
  blue: '#0D99FF',
  accent: '#1384d6',
  yellow: '#FFD452',
  green: '#28A745',
  red: '#FF4444',
};

const theme = createTheme({      
  typography: {
	fontFamily: "Noto Sans",
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    common: {
      ...COLORS
    },
    primary: {
      main: COLORS.blue,
      contrastText: COLORS.white,
    }
  },
  shape: {
    borderRadius: 8,
    roundRadius: '50%',
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto sans !important',
          color: '#000000',
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto sans !important',
          color: '#000000',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto sans !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Noto sans !important',
        }
      }
    }
  },
});

export default theme;