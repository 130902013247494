import React from "react";
import { GamesContext , AuthContext } from "../contexts/contexts.js";

export default function Layout({ children }) {
  return (
    <AuthContext.Consumer>
      {userInfo => (
        <GamesContext.Consumer>
          {gamesInfo => (
            <div>
              {children}
            </div>
          )}
        </GamesContext.Consumer>
      )}
    </AuthContext.Consumer>
  )
}