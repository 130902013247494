import { initializeApp } from "firebase/app";
import 'firebase/auth';
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAZKmzT_PkvNY99oG0nHNUSB4UxSgcMvjI",
  authDomain: "behaviourals-20719.firebaseapp.com",
  projectId: "behaviourals-20719",
  storageBucket: "behaviourals-20719.appspot.com",
  messagingSenderId: "1097974448201",
  appId: "1:1097974448201:web:7b10eda6c80a31fbbc7f98",
  measurementId: "G-ZWDDZECRZG"
};

/*const firebaseConfigDev = {
  apiKey: "AIzaSyBR_ewqpKg7OEkQW7zU3RsK5v0Ist3MfRQ",
  authDomain: "behavioralsdev.firebaseapp.com",
  projectId: "behavioralsdev",
  storageBucket: "behavioralsdev.appspot.com",
  messagingSenderId: "973638737199",
  appId: "1:973638737199:web:03ffa6ec348163c72015da",
  measurementId: "G-LM2QRFRX6Q"
};*/


const app = initializeApp(firebaseConfig);

const auth = getAuth();

const db = getFirestore(app)

const storage = getStorage(app);


export { auth, db, storage };